@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/***** base styles *****/
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand";
  color: #333;
  background-color: #fafafa;
  border-radius: 6px;
  text-align: center;
}
/* #root {
  margin: 0 5px;
} */
.core-content {
  min-height: 800px;
  margin: 0 5px;
}
* h1 {
  font-size: 36px;
}
.delete-button {
  border: none;
}
.delete-icon {
  height: 20px;
  width: 20px;
  padding: 3px;
}
.delete-icon:hover {
  fill: #8b0000;
}
.container {
  margin: 0;
  padding: 0;
}
/***** Body and container CSS Below... *****/
.frame {
  max-width: 800px;
  margin: 10px auto;
}
.kpi-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
}
.kpiTile{
  border: 2px solid #5a1db5;
  padding: 10px 0;
  flex-grow: 1;
  background: #f7f7f7;
  grid-template-rows: 1fr 1fr 20px;
  line-height: 100%;
}
.kpiTile * {
  background: none;
}
.kpiTile a{
  text-decoration: none;
  display: grid;
}
.kpiTile:hover {
  cursor: pointer;
  background: #f4eefc;
}
.kpi-icons{
  fill: #5a1db5;
  height: 45px;
  width: 45px;
  padding: 10px;
}
/***** Footer CSS *****/
.myFooter{
  display: flex;
  flex-wrap: wrap;
  background: #5a1db5;
  border-radius: 0;
  width: 100%;
  margin: 30px 0 0 0;
}
.footer-containers{
  flex-grow: 1;
  max-width: 100%;
  margin: 10px auto;
  padding: 5px 0px;
  background: #5a1db5;
}
.footer-containers a {
  text-decoration: none;
  color: #fff;
  background: none;
}
.myFooter h4 {
  color: #fff;
  background: #5a1db5;
  border-radius: 0;
}
/* .createMaintenance {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.createMaintenance label {
  text-align: left;
  display: block;
}
.createMaintenance input, .createMaintenance textarea, .createMaintenance select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
} */
/***** Less than 800px  *****/
@media (max-width: 800px) {
  .kpi-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .kpiTile a {
    padding: 0;
    grid-template-rows: 30px 1fr 20px;
    height: 100%;
    line-height: 100%;
    margin: 0;
   }
  .acct-container{
    display: grid;
    grid-template-columns: 100%;
    row-gap: 5px;
  }
}

@media (max-width: 325px) {
  .kpi-container {
    display: grid;
    grid-template-columns: 100%;
  }
}