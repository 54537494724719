/* property previews / list */
.property-preview {
    padding: 8px 3px;
    margin: 10px auto;
    border: solid 2px #5a1db5;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "address address"
    "rent issues";
}
.property-list {
    margin: 15px 0 0 0;
    min-height: 200px;
}
.property-preview a {
    text-decoration: none;
}
.property-preview:hover *{
    background: none;
}
.property-preview:hover {
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
    background: #eee;
}
.property-preview:hover h3 {
    color: #5a1db5;
}
.property-preview h3 {
    font-size: 20px;
    color: #588515;
    grid-area: address;
}
.prop-preview-content{
    display: flex;
    flex-grow: 1;
}
.prop-preview-rent {
    padding: 0px 10px;
    flex-grow: 1;
    text-align: left;
    grid-area: rent;
}
.prop-preview-issueCount{
    padding: 0px 10px;
    flex-grow: 1;
    text-align: left;
    grid-area: issues;
}
.property-list button {
    background: #5a1db5;
    color: #fafafa;
    border-radius: 4px;
    border: none;
    width: 125px;
    height: 30px;
}
.property-list button:disabled {
    display: none;
}
.delete-icon {
    height: 20px;
    width: 20px;
}