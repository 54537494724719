/*Account Summary and first bar containers below ... */
.acctSummary{
    border: 2px solid #5a1db5;
    padding: 10px;
    background: #f7f7f7;
  }
.rentAlertBttn{
    border: 2px solid #8b0000;
    height: 100%;
    padding: 0px;
    background: #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .rentAlertBttn * {
    background: none;
    width: 100%;
  }
.rentAlertBttn:hover{
    cursor: pointer;
    background: #ffebeb;
  }
.rentAlertBttn a {
  text-decoration: none;
  padding: 10px;
  background: none;
}
.acct-container{
    display: grid;
    grid-template-columns: 1fr .33fr;
    column-gap: 5px;
  }
.acctSummary * {
    background: none;
}


/* Less than 800px  */
@media (max-width: 800px) {
    .acct-container{
      display: grid;
      grid-template-columns: 100%;
      row-gap: 5px;
    }
}