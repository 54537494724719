.mySettings{
    max-width: 800px;
    margin: 10px auto;
    padding: 5px 0px;
  }
  .mySettings h3{
    text-align: left;
    margin-left: 30px;
    font-size: 20px;
    color: #0bb531;
    margin-bottom: 8px;
  }
  .settings-list{
    padding: 10px 16px;
    margin: 20px 0;
    border-bottom: 1px solid #fafafa;
  }
  .setting-container{
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    margin: 15px auto;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
    text-decoration: none;
    align-items: center;
  }
  .setting-container:hover h3 {
    color: #5a1db5;
  }
  .setting-container:hover {
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  }
  .setting-field{
    flex-grow: 1;
  }
  .setting-icon{
    fill: #5a1db5;
    height: 20px;
    width: 20px;
    margin: 0;
  }
  .setting-value{
    text-align: right;
  } 